import React, { Component } from "react";
import styled from "styled-components";

class Title extends Component {
  render() {
    const { firstText, secondText } = this.props;

    return (
      <Wrapper>
        <div>
          <h2 className="first">{firstText}</h2>
          <h2>{secondText}</h2>
        </div>
        <div className="underline" />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 767px) {
    align-items: flex-end;
  }

  & > .underline {
    width: 100%;
    height: 5px;
    background-color: #e6e54f;
    @media screen and (max-width: 767px) {
      width: 180px;
    }
    @media screen and (max-width: 479px) {
      width: 130px;
    }
  }

  & > div {
    display: flex;
    flex-direction: row;
    padding: 0 10px;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      padding: 0 0 0 10px;
    }

    & > h2 {
      font-size: 52px;
      color: #224e86;
      line-height: 80px;
      @media screen and (max-width: 767px) {
        text-align: right;
      }

      &.first {
        margin-right: 20px;
        @media screen and (max-width: 767px) {
          margin-right: 0;
        }
      }
    }
  }
`;

export default Title;
