import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { withPage } from "../Page";
import * as Widget from "../Components/Widget";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
import successImg from "../../static/images/success.png";
import Title from "../Components/Title";

class SuccessPage extends Component {
  componentDidMount() {
    const { profile, navActions } = this.props;

    if (profile) {
      return setTimeout(() => navActions.push("/me"), 1500);
    }

    return setTimeout(() => navActions.push("/"), 1500);
  }

  render() {
    return (
      <Wrapper>
        <Widget.Col align="center">
          <Head>
            <Title firstText="Update" secondText="Completed" />
          </Head>
          <Container>
            <p>更新成功</p>
            <img src={successImg} />
          </Container>
        </Widget.Col>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  padding: 20px;
`;

const Head = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 767px) {
    width: 460px;
    align-items: flex-end;
    justify-content: space-between;
  }
  @media screen and (max-width: 479px) {
    width: 100%;
  }
`;

const Container = styled.div`
  width: 460px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 479px) {
    width: 100%;
    align-items: flex-start;
  }

  & > p {
    font-size: 40px;
    color: #224e86;
    text-align: center;
    @media screen and (max-width: 479px) {
      width: 100%;
      font-size: 20px;
      text-align: right;
    }
  }
  & > img {
    width: 190px;
    height: 188px;
    margin-top: 80px;
  }
`;

export default withPage(
  connect(
    (state, ownProps) => ({
      profile: Selectors.getLoginUser(state)
    }),
    ActionCreator
  )(SuccessPage)
);
